.wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
}

.amount {
    margin-right: 4px;
    font-size: 24px;
}

.currency {
    font-weight: 500;
}

.description {
    margin-top: 4px;
}