body, html {
    padding: 0;
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
    font-family: 'Rubik';
    font-size: 12px;
    color: #37414B;
}